import React from "react";
import "./App.css"

function Navbar () {
    return(
        <div className="navbar-all-links">
            <a href="./home"><img className="nav-logo" src="./nm.png" /></a>
            <hr/>
            <nav class="navbar navbar-expand-lg bg-body-tertiary">
                <div class="container-fluid">
                    <a class="logo" href="./home"><img className="nav-home-logo" src="./homel.png" /></a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse nav-cen" id="navbarNav">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                        <a class="nav-link" href="./education">Education</a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link" href="./learning">Learning</a>
                        </li> 
                        <li class="nav-item">
                        <a class="nav-link" href="./humor">Humor & Comics</a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link" href="./news">News</a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link" href="./health">Health</a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link" href="./polls">Opinion Polls</a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link" href="./reforms">Reforms</a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link" href="./visions">Vision for world</a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link" href="./guidens">Videos</a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link" href="./articles">Articles</a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link" href="./role">Role Model</a>
                        </li>
                        <li class="nav-item">
                            <button className="vi-but" href="#">Subscribe</button>
                        </li>
                    </ul>
                    </div>
                    
                </div>
            </nav>
        </div>
    )
}

export default Navbar
