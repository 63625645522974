import React from "react";

function World () {
    return(
        <div>
            <div class="container">
                <h3 className="todays-news text-center">EDUCATION</h3>
                <img className="to-do-img" src="./tod.png" />
                <div class="row align-items-start">
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours" src="./ani.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">THE SPORTING SCENE</p>
                                    <a class="card-title-around" href="#">Donald Trump's Go-To Dance Move Has Invaded Sports</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" class="name-name">by louisa Thomas</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-two" src="./an.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">THE SPORTING SCENE</p>
                                    <a class="card-title-around" href="#">Donald Trump's Go-To Dance Move Has Invaded Sports</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" class="name-name">by louisa Thomas</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-three" src="./ani.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">THE SPORTING SCENE</p>
                                    <a class="card-title-around" href="#">Donald Trump's Go-To Dance Move Has Invaded Sports</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" class="name-name">by louisa Thomas</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-four" src="./an.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">THE SPORTING SCENE</p>
                                    <a class="card-title-around" href="#">Donald Trump's Go-To Dance Move Has Invaded Sports</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" class="name-name">by louisa Thomas</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <button className="view-all" href="#">View All</button>
            </div>
            <br/>
            <div className="box-container">
                <div className="row">
                    <div className="col-md-4 four-cont-col">
                        <h4 className="fiction">Fiction</h4>
                        <h1 className="paris">“Paris Friend”</h1>
                        <img className="box-cont-img" src="./art.png" />
                        <br/>
                        <a className="box-font-name">By Shuang Xuetao</a>
                        <p className="box-font-para">Illustration by Joey Yu</p>
                    </div>
                    <div className="col">
                        <p className="box-font-info">​​Xiaoguo had a terror of thirst, so he kept a glass of water on the table beside his hospital bed. As soon as it was empty, he asked me to refill it. I wanted to warn him that this was unhealthy—guzzling water all night long puts pressure on the kidneys, and pissing that much couldn’t be good for his injury. He was tall, though, so I decided his insides could probably cope.</p>
                        <p className="box-font-info-two">​​Xiaoguo had a terror of thirst, so he kept a glass of water on the table beside his hospital bed. As soon as it was empty, he asked me to refill it. I wanted to warn him that this was unhealthy—guzzling water all night long puts pressure on the kidneys, and pissing that much couldn’t be good for his injury. He was tall, though, so I decided his insides could probably cope.</p>
                        <a className="continue-read">Continue Reading...</a>
                        <hr/>
                        <a className="week-in">This Week in Fiction</a>
                        <br/>
                        <a className="week-in">Shuang Xuetao on Memory as a Movie</a>
                        <br/>
                        <a className="week-in">All fiction</a>
                    </div>
                </div>
            </div>
            <br/>
            <hr/>

            <div class="container">
            <br/>
                <h3 className="todays-news text-center">VISION FOR WORLD</h3>
                <img className="to-do-img" src="./tod.png" />
                <div class="row align-items-start">
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours" src="./ani.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">THE SPORTING SCENE</p>
                                    <a class="card-title-around" href="#">Donald Trump's Go-To Dance Move Has Invaded Sports</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" class="name-name">by louisa Thomas</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-two" src="./an.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">THE SPORTING SCENE</p>
                                    <a class="card-title-around" href="#">Donald Trump's Go-To Dance Move Has Invaded Sports</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" class="name-name">by louisa Thomas</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-three" src="./ani.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">THE SPORTING SCENE</p>
                                    <a class="card-title-around" href="#">Donald Trump's Go-To Dance Move Has Invaded Sports</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" class="name-name">by louisa Thomas</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-four" src="./an.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">THE SPORTING SCENE</p>
                                    <a class="card-title-around" href="#">Donald Trump's Go-To Dance Move Has Invaded Sports</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" class="name-name">by louisa Thomas</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <button className="view-all" href="#">View All</button>
            </div>
            <br/>
            <hr/>

            <div className="bk-tr">
                <p className="trump-cmt" href="#">Comment</p>
                <p className="trump-title" href="#">STOPPING THE PRESSURE</p>
                <p className="trump-para" href="#">After spending years painting the media as the “enemy of the people,” Donald</p>
                <p className="trump-para-two">Trump is ready to intensify his battle against the journalists</p>
                <p className="trump-para-thr">who cover him.</p>
                <br/>
                <p className="trump-name" href="#">By David Remnick</p>
            </div>
            <hr/>

            <div class="container">
            <br/>
                <h3 className="todays-news text-center">REFORMS</h3>
                <img className="to-do-img" src="./tod.png" />
                <div class="row align-items-start">
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours" src="./ani.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">THE SPORTING SCENE</p>
                                    <a class="card-title-around" href="#">Donald Trump's Go-To Dance Move Has Invaded Sports</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" class="name-name">by louisa Thomas</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-two" src="./an.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">THE SPORTING SCENE</p>
                                    <a class="card-title-around" href="#">Donald Trump's Go-To Dance Move Has Invaded Sports</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" class="name-name">by louisa Thomas</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-three" src="./ani.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">THE SPORTING SCENE</p>
                                    <a class="card-title-around" href="#">Donald Trump's Go-To Dance Move Has Invaded Sports</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" class="name-name">by louisa Thomas</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-four" src="./an.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">THE SPORTING SCENE</p>
                                    <a class="card-title-around" href="#">Donald Trump's Go-To Dance Move Has Invaded Sports</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" class="name-name">by louisa Thomas</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <button className="view-all" href="#">View All</button>
            </div>
            <br/>
            <hr/>
        </div>
    )
}

export default World