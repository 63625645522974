import React from "react";
import "./App.css"

function Footer () {
    return(
        <div>
            <div class="container-footer">
            <a href="./home"><img className="nav-logo foo" src="./fm.png" /></a>
                <hr className="line"></hr>
                <div class="row">
                    <div class="col">
                        <h5 className="footer-h">Sections</h5>
                        <a className="footer-links-gray">News</a>
                        <br/>
                        <a className="footer-links-gray">Education</a>
                        <br/>
                        <a className="footer-links-gray">Learning</a>
                        <br/>
                        <a className="footer-links-gray">Cartoon & Humor</a>
                        <br/>
                        <a className="footer-links-gray">Health</a>
                    </div>
                    <div class="col">
                    <h5 className="footer-h-s">.</h5>
                        <a className="footer-links-gray">Opinon Polls</a>
                        <br/>
                        <a className="footer-links-gray">Reforms</a>
                        <br/>
                        <a className="footer-links-gray">Vision for world</a>
                        <br/>
                        <a className="footer-links-gray">Guidenes</a>
                        <br/>
                        <a className="footer-links-gray">Subscribe</a>
                    </div>
                    <div class="col">
                        <h5 className="footer-h">More</h5>
                        <a className="footer-links-gray">Manage Account</a>
                        <br/>
                        <a className="footer-links-gray">Shop</a>
                        <br/>
                        <a className="footer-links-gray">Buy Covers and Cartoons</a>
                        <br/>
                        <a className="footer-links-gray">Play</a>
                    </div>
                    <div class="col">
                        <h5 className="footer-h-s">.</h5>
                        <a className="footer-links-gray">Digital Access</a>
                        <br/>
                        <a className="footer-links-gray">Newsletters</a>
                        <br/>
                        <a className="footer-links-gray">Jigsaw Puzzle</a>
                        <br/>
                        <a className="footer-links-gray">RSS</a>
                    </div>
                </div>
                <hr className="line"></hr>
                <div class="row">
                    <div class="col">
                        <a className="footer-links-gray" href="./about">About</a>
                        <br/>
                        <a className="footer-links-gray">Careers</a>
                        <br/>
                        <a className="footer-links-gray">Contact</a>
                    </div>
                    <div class="col">
                        <a className="footer-links-gray">F.A.Q.</a>
                        <br/>
                        <a className="footer-links-gray">Media Kit</a>
                        <br/>
                        <a className="footer-links-gray">Press</a>
                    </div>
                    <div class="col">
                        <a className="footer-links-gray">Accessibility Help</a>
                        <br/>
                        <a className="footer-links-gray">User Agreement</a>
                    </div>
                    <div class="col">
                        <a className="footer-links-gray">Privacy Policy</a>
                        <br/>
                        <a className="footer-links-gray">Privacy Rights</a>
                    </div>
                </div>
                <hr className="line"></hr>
                <div class="row copy">
                    <div class="col-md-4">
                        <p>@2024 All Copy rights reserved to Times Quest</p>
                    </div>
                    <div class="col-md-1 footer-icons-ic">
                        <img className="footer-social-icon" src="./facebook.jpg" />
                    </div>
                    <div class="col-md-1 footer-icons-ic">
                        <img className="footer-social-icon" src="./x.png" />
                    </div>
                    <div class="col-md-1 footer-icons-ic">
                        <img className="footer-social-icon" src="./insta.jpg" />
                    </div>
                    <div class="col-md-1 footer-icons-ic">
                        <img className="footer-social-icon-you" src="./youtube.png" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer