import React from "react";

function Draw () {
    return(
        <div>
            <div className="draw-cont">
                <div className="row">
                    <div className="col draw-contone">
                        <p className="draw-li" href="#">ON AND Off THE AVENUE</p>
                        <a className="draw-gift" href="#">A GIFT GUIDE FULL OF LITTLE TREASURES</a>
                        <br/>
                        <a className="draw-id" href="#">Ideas for holiday shopping that makes life a litle easier or just more sparkly.</a>
                        <br/>
                        <br/>
                        <a className="draw-name" href="#">By Rachel Syme</a>
                    </div>
                    <div className="col">
                        <img className="draw-img" src="./draw.png" />
                    </div>
                </div>
            </div>
            <hr/>

            <div class="container">
            <br/>
                <h3 className="todays-news text-center">REFORMS</h3>
                <img className="to-do-img" src="./tod.png" />
                <div class="row align-items-start">
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours" src="./ani.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">THE SPORTING SCENE</p>
                                    <a class="card-title-around" href="#">Donald Trump's Go-To Dance Move Has Invaded Sports</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" class="name-name">by louisa Thomas</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-two" src="./an.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">THE SPORTING SCENE</p>
                                    <a class="card-title-around" href="#">Donald Trump's Go-To Dance Move Has Invaded Sports</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" class="name-name">by louisa Thomas</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-three" src="./ani.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">THE SPORTING SCENE</p>
                                    <a class="card-title-around" href="#">Donald Trump's Go-To Dance Move Has Invaded Sports</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" class="name-name">by louisa Thomas</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="card-one">
                            <div class="card">
                                <img className="img-fours-four" src="./an.png" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    <p className="sports">THE SPORTING SCENE</p>
                                    <a class="card-title-around" href="#">Donald Trump's Go-To Dance Move Has Invaded Sports</a>
                                    <br/>
                                    <br/>
                                    <p class="card-text-around">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" class="name-name">by louisa Thomas</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <button className="view-all" href="#">View All</button>
            </div>
        </div>
    )
}

export default Draw