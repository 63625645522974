import React from "react";
import Footer from "./footer";
import Navbar from "./navbar";

function Education () {
    return(
        <div>
            <Navbar/>
            <div>
            <div className="cont-vis-banner">
                <p className="vis-tit">Education</p>
                <p className="vis-para">Quest for knowledge, wisdom and transformation.</p>
            </div>
            <div className="cont-one-education">
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">What Can Stop the Cycle of Education in Ukraine?</p>
                                <a className="edu-para-one" href="#">As the Biden Administration approves new weaponry for Ukrainian forces, Putin has invoked Russia’s nuclear arsenal, but neither move is likely to significantly alter the trajectory of the war.</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Joshua Yaffa</a> 
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="#">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./rus.png" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" /> 

                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">What Can Stop the Cycle of Education in Ukraine?</p>
                                <a className="edu-para-one" href="#">As the Biden Administration approves new weaponry for Ukrainian forces, Putin has invoked Russia’s nuclear arsenal, but neither move is likely to significantly alter the trajectory of the war.</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Joshua Yaffa</a>
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="#">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./rus.png" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" /> 

                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">What Can Stop the Cycle of Education in Ukraine?</p>
                                <a className="edu-para-one" href="#">As the Biden Administration approves new weaponry for Ukrainian forces, Putin has invoked Russia’s nuclear arsenal, but neither move is likely to significantly alter the trajectory of the war.</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Joshua Yaffa</a>
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="#">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./rus.png" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" /> 

                        <div className="row">
                            <div className="col-md-10">
                                <p className="edu-title-one" href="#">What Can Stop the Cycle of Education in Ukraine?</p>
                                <a className="edu-para-one" href="#">As the Biden Administration approves new weaponry for Ukrainian forces, Putin has invoked Russia’s nuclear arsenal, but neither move is likely to significantly alter the trajectory of the war.</a>
                                <br/>
                                <a className="edu-name-one" href="#">By Joshua Yaffa</a>
                                <br/>
                                <a className="edu-date-one" href="#">November 27, 2024</a> <a className="edu-link-one" href="#">Read more...</a>
                            </div>
                            <div className="col-md-2">
                                <img className="blog-one-edu" src="./rus.png" />
                            </div>
                        </div>   
                        <br/>
                        <hr className="hr-line" />        
                    </div>

                    <div className="col-md-4">
                        Hi
                    </div>
                </div>
                <br/>
            </div>
            </div>
            <Footer />
        </div>
    )
}

export default Education