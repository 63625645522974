import React from "react";
import Cor from "./cor";
import Draw from "./draw";
import Footer from "./footer";
import Navbar from "./navbar";
import Today from "./today";
import World from "./world";

function Home () {
    return(
        <div>
            <Navbar/>
            <Cor />
            <br/>
            <Today />
            <br/>
            <World />
            <br/>
            <Draw />
            <br/>
            <Footer />
        </div>
    )
}

export default Home