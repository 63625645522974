import logo from './logo.svg';
import './App.css';
import Home from './home';
import { BrowserRouter, Route } from 'react-router';
import { Routes, Switch } from 'react-router';
import Education from './education';
import Learning from './learning';
import Humor from './humor';
import News from './news';
import Health from './health';
import Polls from './polls';
import Reforms from './reforms';
import Visions from './visions';
import Guidens from './guidens';
import About from './about';
import Articles from './articles';
import Role from './role';
import Blogone from './blogs/blogone';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        <Route exact path="/*" element={<Home />} />
          <Route exact path="home/*" element={<Home />} />
          <Route path="education/*" element={<Education />} />
          <Route exact path="learning/*" element={<Learning />} />
          <Route exact path="humor/*" element={<Humor />} />
          <Route exact path="news/*" element={<News />} />
          <Route exact path="health/*" element={<Health />} />
          <Route exact path="polls/*" element={<Polls />} />
          <Route exact path="reforms/*" element={<Reforms />} />
          <Route exact path="visions/*" element={<Visions />} />
          <Route exact path="guidens/*" element={<Guidens />} />
          <Route exact path="articles/*" element={<Articles />} />
          <Route exact path="role/*" element={<Role />} />
          <Route exact path="about/*" element={<About />} />

          <Route exact path="blogone/*" element={<Blogone />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
